<template>
  <div :class="getClass(dashboardLayoutCard.dashboardCardTypeCode, dashboardLayoutCard.sequenceNumber)">
    <div class="dashboard-header">
      <div class="dashboard-title">
        <div class="dashboard-title-details">
          <a href="/analytics/trends" @click="$parent.logViewDetailsEvent(title)">            
            {{ title }}
          </a>
          <smart-center-link v-if="displayDashboard" page="Competitive Dashboard" location="Performance Trends" />
        </div>
        <div class="dashboard-save-dashboard-card"  @click="$modal.show('performance-trends-save-chart-confirmation')"> 
              Save for Report
              <span class="m-1">
                <font-awesome-icon :icon="['fa', 'floppy-disk']" />
              </span>
        </div>
      </div>
      <div class="dashboard-widgets dashboard-performance-trends">
        <dashboard-card-dropdown label="Performance" :read-only="readOnly" :options="performance_options" :default-value="performance" @change="savePerformance"></dashboard-card-dropdown>
        <dashboard-card-dropdown label="Metric" :read-only="readOnly" :options="inputMetricOptions" :default-value="metricId" @change="saveMetric"></dashboard-card-dropdown>
      </div>
      <dashboard-card-expand :dashboard-id="dashboardId" />
    </div>
    <div class="dashboard-divider"></div>
    <div class="dashboard-card-body">
      <vue-skeleton-loader
            v-if="!displayDashboard" 
            type="rec"
            width="330"
            height="230"
            animation="fade"
        />         
      <div class="performance-trends-dashboard-chart" :style="dashboardChartStyle">
        <chart :options="options"></chart>
      </div>
      <div id="performance-trends-mini">
        <img :src="imageUrl" class="pdf-content-image-width hidden-dashboard-display"/>
      </div> 
    </div>
<!--  :updatedModalChartOptions.sync="options.series[0].data" -->        
    <dashboard-modal :modal-id="dashboardId" :chart="this.chart" :client-chart="this.clientChart" :dashboard-card-report-page-type-name="reportTypeName" :modal-title="title" :modal-chart-options="options" />
    <vue-modal :ref="modalName" :name="modalName" :width="350" :height="200">
      <save-chart :modal-name="modalName" button-label="Save for Report" :chart-title="this.chartTitle" :initial-client-chart="this.clientChart" @save="this.updateClientCharts" @closeModal="closeModal(this.modalName)"></save-chart>
    </vue-modal>
  </div>
</template>

<script>
import DashboardMixins from "../../mixins/DashboardMixins.vue";
import DashboardService from "../../services/dashboard.service";
import AdminService from "../../services/admin.service";
import ClientService from "../../services/client.service";
import Vue from "vue";
import SaveChart from "../chart/SaveChart.vue";
import VueSkeletonLoader from 'skeleton-loader-vue';

import "echarts/lib/chart/heatmap";
import echarts from "echarts";

import $ from "jquery";
import { markRaw } from 'vue'; 
import DashboardCardDropdown from "./DashboardCardDropdown";

export default {
  name: "DashboardPerformanceTrends",
  mixins: [DashboardMixins],
  components: { DashboardCardDropdown, SaveChart, VueSkeletonLoader },
  data() {
    return {
      title: "Performance Trends",
      clientLabelColor: "",
      modalName: "performance-trends-save-chart-confirmation",
      performance_options: [
        { name: "Willingness to Pay", value: "Willingness to Pay" },
        { name: "Satisfaction", value: "Satisfaction" },
        { name: "Emotions", value: "Emotions" },
        { name: "Moat Score", value: "Moat Score" },
        { name: "NPS", value: "NPS" }
      ],
      performance: "Willingness to Pay",
      metricId: "",
      metricDisplayTypeName: "",
      useMetricGrowth: false,
      usePercentage: true,
      performanceScatterSeries: {
        name: "Satisfaction",
        type: "line",
        smooth: false,
        showAllSymbol: true,
        symbol: 'pin',
        step: false,
        // symbolSize: 15,
        lineStyle: { width: 4 }, 
        z: 14,
        data: [],
        itemStyle: {
          color: "#1c408e"
        }
      },
      metricBarSeries: {
        name: "",
        type: "bar",
        //barWidth: 20,
        yAxisIndex: 1,
        itemStyle: {
          color: "#339966"
        },
        data: []
      },
      performanceBarSeries: {
        name: "",
        type: "bar",
        barGap: "-100%",
        barWidth: 1,
        itemStyle: {
          color: "#DCDCDC"
        },
        z: 12,
        data: []
      }
    };
  },
  props: {
    inputMetricOptions: {
      type: Array,
      default: function() {
        return [];
      }
    },    
    inputMetricId: {
      type: String,
      default: ""
    },
  },
  computed: {},
  mounted() {
// eslint-disable-next-line no-console    
    //console.log("DashboardPerformanceTrends.mounted");
    this.metricId = this.inputMetricId;
    this.chartTitle = "Performance Trends";
    this.chartTypeName = "performance-trends";
    this.containerId = "performance-trends-mini"
	  this.reportTypeName = "Dashboard - Performance Trends"
    this.resetChart();
  },
  created() {},
  methods: {
    processSavedChartCriteria(criteria) {
      let criteriaParameters = criteria.split("=");
      let isMetric = criteriaParameters[0] == "metric" ? true : false;
  		if (isMetric){
        this.metricId = criteriaParameters[1];
  		}
      let isPerformance = criteriaParameters[0] == "performance" ? true : false;
  		if (isPerformance){
        this.performance = criteriaParameters[1];
  		}
      let isUseMetricGrowth = criteriaParameters[0] == "useMetricGrowth" ? true : false;
  		if (isUseMetricGrowth){
        this.useMetricGrowth = criteriaParameters[1];
  		}
    },
    buildUrlParams(client_id) {
      let params = this.buildBaseUrlParams();
      params.append("metric",this.metricId);
      params.append("useMetricGrowth",this.useMetricGrowth);
      params.append("performance",this.performance);
      return params;
    },      
    initChart(dynamicOptions) {
      //  https://echarts.apache.org/examples/en/editor.html?c=pictorialBar-dotted
      let chartDiv = $(".performance-trends-dashboard-chart")[0];
      this.chart = markRaw(echarts.init(chartDiv));
      let vm = this;
      this.chart.on('finished', () => {
        vm.saveImage();
      });
      this.options = DashboardService.getPerformanceTrendsOptions(echarts, ClientService.getClientColor());

      DashboardService.setIndividualSeries(this.performanceScatterSeries, 0, dynamicOptions);
      DashboardService.setIndividualSeries(this.metricBarSeries, 1, dynamicOptions);
      
      this.options.legend.data = DashboardService.setLegend(this.options.legend.data, dynamicOptions);
      $.extend(true, this.options, dynamicOptions);
      if (this.performance == "Willingness to Pay") {
        let pricingPowerAxisLabel = {
          formatter: function(value, index) {
            return value.toFixed(1) + " %";
          }
        };
        $.extend(true, this.options.yAxis[0].axisLabel, pricingPowerAxisLabel);
      }
      let yAxisLabel = {
        formatter: function(value, index) { 
          return value.toFixed(1);
        }
      }
      if (this.usePercentage){
          yAxisLabel = {
            formatter: function(value, index) { 
              return value +" %";
            }
          }        
      } else if (this.metricDisplayTypeName == "Currency"){
          yAxisLabel = {
            formatter: function(value, index) { 
              return "$ " + value.toLocaleString();
            }
          }        
      }
      $.extend(true, this.options.yAxis[1].axisLabel, yAxisLabel);
      this.options.yAxis[1].min = null;
      let yAxisMax  = {
        max: function (value) {
          return value.max + 1;
        }
      };
      $.extend(true, this.options.yAxis[1].max, yAxisMax);
      
      if (this.autoGenerateChartRun == true){
        this.options.legend.show = true;
      }
      //this.printOptions();
      this.chart.setOption(this.options, true);
    },
    loadData: function() {},
    initialLoadData: async function() {
// eslint-disable-next-line no-console      
      //console.log("DashboardPerformanceTrends.initialLoadData");
      this.clientLabelColor = ClientService.getClientColor();
      this.displayDashboard = false;
      if (this.autoGenerateChartRun){
        this.processAutoGenerateChart();
      } else {
        this.metricId = this.inputMetricId;
      }   
      this.updateCard();
    },
    savePerformance: function(value) {
      this.performance = value;
      DashboardService.setDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_PERFORMANCE_TRENDS_PERFORMANCE_SETTING_TYPE,this.performance, this.performance_options);
      this.updateCard();
    },
    saveMetric: function(value) {
      this.metricId = value;
      this.useMetricGrowth = false;
      DashboardService.setDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_PERFORMANCE_TRENDS_METRIC_SETTING_TYPE, this.metricId, this.inputMetricOptions);
      this.updateCard();
    },
    updateCard: function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardPerformanceTrends.updateCard");
      // eslint-disable-next-line no-console
      //console.log("updateCard Performance Trends");
      this.parseMetric();
      this.displayDashboard = false;
      let vm = this;
      setTimeout(function() {
        if (!vm.autoGenerateChartRun){
          vm.performance = DashboardService.getDashboardSetting(vm.$parent.defaultDashboardValues, DashboardService.DASHBOARD_PERFORMANCE_TRENDS_PERFORMANCE_SETTING_TYPE, vm.performance, vm.performance_options);
          vm.metricId = DashboardService.getDashboardSetting(vm.$parent.defaultDashboardValues, DashboardService.DASHBOARD_PERFORMANCE_TRENDS_METRIC_SETTING_TYPE, vm.metricId, vm.inputMetricOptions);
        }
        if (typeof vm.metricId != "undefined" && vm.metricId.includes("growth")) {
          vm.useMetricGrowth = true;
        }
        DashboardService.getPerformanceTrends(vm.performance, vm.metricId, vm.useMetricGrowth, vm.getPerformanceTrendsCallback, vm);
      }, 100);
    },
    getPerformanceTrendsCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.displayDashboard = true;
        let dynamicOptions = response.data.data.options;
        dynamicOptions.series[0].step = false;
        this.metricDisplayTypeName = response.data.data.metricDisplayTypeName;
        this.usePercentage = response.data.data.usePercentage;
        this.initChart(dynamicOptions);
        if (!this.autoGenerateChartRun){
          this.setupClientChart();
        }      
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
  },
  watch: {
    entrySurveyCode: function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardPerformanceTrends::entrySurveyCode");
      this.resetChart();
    },
  }
};
</script>
