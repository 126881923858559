import BaseChartService from "./base.chart.service";
import $ from "jquery";

class StackedAreaService extends BaseChartService {
    constructor() {
        super();
    }    
    getBaseSeries() {
        let labelTextStyle = this.getDefaultLabelTextStyle();

        let label = {
            show: this.getShowLabel(),
            textStyle: labelTextStyle,        
            formatter: function(param) {
                return param.value == 0 ? '' : param.value + '%'
            }
        }
        return {
            type: 'line',
            stack: 'total',
            label: label,
            areaStyle: {},
            emphasis: {
                focus: 'series'
            },
        };
    }
    setData(inputTimePeriods,selectedQuestion) {
        let series = [];
        let axisData = {
            name: "",
            data: []
        };           
        for (let i = 0; i < selectedQuestion.options.length; i++) {
            let option = selectedQuestion.options[i];
            axisData = {
                name: option.label,
                data: []
            };         
            for (let j = 0; j < inputTimePeriods.length; j++) {
                let selectedTimePeriod = inputTimePeriods[j];
                let countItem = option.counts.find(item => item.timePeriod === selectedTimePeriod);
                if (typeof countItem != 'undefined' && countItem.count != null) {
                    axisData.data.push(countItem.count);
                } 
            }     
            $.extend(true, axisData, this.getBaseSeries());
            series.push(axisData);
        }
        this.setSeries(series);
        this.setXAxisData(inputTimePeriods);
    }
    getOptions(disableSelectedMode = false,app,modalId,reportPageTypeName) {
        let legend;
        if (disableSelectedMode == true){
            legend = {selectedMode: false};
            $.extend(true, legend, this.getDefaultLegendResponseRatesChart());        
        } else {    
            legend = this.getDefaultLegendResponseRatesChart();
        }     
        let axisLabel = {
            formatter: '{value}%'
        }   
        $.extend(true, axisLabel, this.getDefaultAxisTextStyle());                       
        let tooltip = {
            axisPointer: {
                type: 'shadow'
            },
            formatter: this.toolTipForStackedAreaChart,
            textStyle: this.getDefaultLabelTextStyle()
        };
        $.extend(true, tooltip, this.getDefaultToolTip('axis'));           
        let yAxis = {
            type: 'value',
            axisLabel: axisLabel,
            splitLine: {
                lineStyle: {
                  color: this.getBaseLineColor(),
                  width: this.getBaseLineWidth()
                }
            }                               
        };
        if (disableSelectedMode == true){
            $.extend(true, yAxis, {min: 0, max: 100});
        }            
        let option = {
            toolbox: this.getDefaultToolBox(app,modalId,reportPageTypeName),
            tooltip: tooltip,
            legend: legend,
            grid: this.getShowLegend() ? this.getGrid() : this.getGridBackground(),
            xAxis: {
                type: 'category',
                boundaryGap: true,
                data: this.getXAxisData(),    
                axisLabel: this.getDefaultAxisTextStyle(),
                axisTick:{
                    show: false,
                },                                                         
            },
            yAxis: yAxis,
            series: this.getSeries(),
            color: this.getColorPalette()
        };

        return option;
    }
}
export default new StackedAreaService();
