<template>
    <div class="col-12 no-gutters container mt-3">
        <div class="e-chart-container">
            <div class="title-and-chart">
                <div :id="eChartId" class="e-chart">
                    <chart :options="chart.options"></chart>
                </div>
                <div :id="eChartMiniId">
                    <img :src="imageUrl" class="pdf-content-image-width hidden-dashboard-display"/>
                </div>             
            </div>
        </div>
    </div>
</template>

<script>
import ScatterService from "../../services/echarts/scatter.service";
import ReportService from "../../services/report.service";
import ProfileService from "../../services/echarts/profile.service";
import NeedsService from "../../services/needs.service";
import EChartsMixins from "../../mixins/EChartsMixins";

import $, { isEmptyObject } from "jquery";

export default {
  name: "ProfileChart",
  mixins: [EChartsMixins],
  components: {},
  data() {
    return {
        charts: [],
        legend : [],
        colors: [],
        emotionalNeedsNameOrder: [],
        driverNames: [],
        displayPricingPower: false,
        pricingPowerSeriesNumber: 0,
        addWrapper: false,
        maxPricingPower: 0,
        maximumAxisLabelWidth: 0,
        totalCharacters: 0,
        matchType: "",
    };
  },
  computed: {
    yAxisForChart2: function(){
        return this.displayPricingPower == true ? 2 : 1;
    }, 
    yAxisForPricingPowerChart2: function(){
         return this.displayPricingPower == true ? 3 : 2;
    }
  },
  mounted() {},
  created() {},
  methods: {
    //https://codepen.io/plainheart/pen/bGgmGPj    
    setDataForNeed: function(needsData,chart1,chart2 = {},displayType){
        let chart1Data = {};
        let chart2Data = {};        
        this.setDataForBaseChart(needsData,chart1,chart2,displayType);        
        if (typeof needsData != "undefined" && !$.isEmptyObject(needsData)) {
            if (typeof needsData.chart1 != "undefined" && needsData.chart1.data.length > 0){
                chart1Data = JSON.parse(JSON.stringify(needsData.chart1));
                if (typeof needsData.chart1.containerId != "undefined"){
                    chart1.containerId = needsData.chart1.containerId;
                }
            }                
            if (typeof needsData.chart2 != "undefined" && needsData.chart2.data.length > 0){
                chart2Data = JSON.parse(JSON.stringify(needsData.chart2));
                if (typeof needsData.chart2.containerId != "undefined"){
                    chart2.containerId = needsData.chart2.containerId;
                }
            }               
            if (typeof needsData.pricingPowerSeriesNumber != "undefined"){
                this.pricingPowerSeriesNumber = JSON.parse(JSON.stringify(needsData.pricingPowerSeriesNumber));
                this.pricingPowerSeriesNumber = Number(this.pricingPowerSeriesNumber);
                this.displayPricingPower = true;
            } else {
                this.displayPricingPower = false;
                this.pricingPowerSeriesNumber = 0;
            }
        }
        let displayedLegend = [];
        let displayNumberOfRespondents = this.eChartCriteria.displayNumberOfResponses;
        if (displayNumberOfRespondents && typeof this.legend.respondents != "undefined"){
            displayedLegend = JSON.parse(JSON.stringify(this.legend.respondents));
        } else {
            displayedLegend = JSON.parse(JSON.stringify(this.legend.normal));
        }
         

        for (let legendIndex = 0; legendIndex < displayedLegend.length; legendIndex++) {
            let legend = displayedLegend[legendIndex];
            let legendName = legend.name;
            if (legendIndex == 0){
                let chart1Series = ProfileService.getSeries(legendName,0,0,0,ProfileService.getExpectationsConfig(chart1.containerId));
                if (displayType == "winner" || displayType == "match-winner"){  
                    $.extend(true, chart1Series, ProfileService.getMarkPointForEmotionalNeeds(true));
                }
                chart1.series.push(chart1Series);
                if (!$.isEmptyObject(chart2)){                
                    let chart2Series = ProfileService.getSeries(legendName,1,2,this.yAxisForChart2,ProfileService.getExpectationsConfig(chart2.containerId),chart2.containerId);
                    $.extend(true, chart2Series, ProfileService.getMarkPointForEmotionalNeeds(false));
                    chart2.series.push(chart2Series);
                }
            } else if (legendIndex == 1){
                chart1.series.push(ProfileService.getSeries(legendName,0,0,0,ProfileService.getDefaultClientSymbolConfig(this.symbolType)));
                if (!$.isEmptyObject(chart2)){                
                    chart2.series.push(ProfileService.getSeries(legendName,1,2,this.yAxisForChart2,ProfileService.getDefaultClientSymbolConfig(this.symbolType)));
                }
            } else if (this.displayPricingPower && (legendIndex == this.pricingPowerSeriesNumber-1)){
                let pricingPowerColor = "rgb(215, 228, 189)";
                if (this.colors.length > (this.pricingPowerSeriesNumber-1)) {
                    pricingPowerColor = this.colors[this.pricingPowerSeriesNumber-1];
                }
                chart1.series.push(ProfileService.getPricingPowerSeries(0,0,1,pricingPowerColor));
                if (!$.isEmptyObject(chart2)){                
                    chart2.series.push(ProfileService.getPricingPowerSeries(1,2,this.yAxisForPricingPowerChart2,pricingPowerColor));
                }
            } else {
                let isMatch = (this.matchType == "time-period" || this.matchType == "saved-card" || this.matchType == "survey")? true : false;
                let symbolSize = isMatch ? 13 : 10;
                if (legend.symbolClass.includes("bar")){
                    let modifiedCompetitorConfig = isMatch == true ? 
                        JSON.parse(JSON.stringify(ProfileService.getDefaultClientSymbolConfig(this.symbolType))) :
                        JSON.parse(JSON.stringify(ProfileService.getCompetitorConfig("circle",symbolSize)));
                    $.extend(true, modifiedCompetitorConfig, ProfileService.getExpectationsConfig(chart1.containerId));
                    chart1.series.push(ProfileService.getSeries(legendName,0,0,0,modifiedCompetitorConfig));
                    if (!$.isEmptyObject(chart2)){                                    
                        chart2.series.push(ProfileService.getSeries(legendName,1,2,this.yAxisForChart2,modifiedCompetitorConfig));
                    }
                } else {
                    let modifiedCompetitorConfig = isMatch == true ? 
                        JSON.parse(JSON.stringify(ProfileService.getDefaultClientSymbolConfig(this.symbolType))) :
                        JSON.parse(JSON.stringify(ProfileService.getCompetitorConfig("circle",symbolSize)));
                    chart1.series.push(ProfileService.getSeries(legendName,0,0,0,modifiedCompetitorConfig));
                    if (!$.isEmptyObject(chart2)){                                    
                        chart2.series.push(ProfileService.getSeries(legendName,1,2,this.yAxisForChart2,modifiedCompetitorConfig));
                    }
                }
            }
        }
        chart1 = this.setChart(chart1Data, chart1);
        this.maximumAxisLabelWidth = this.findLongestStringLength(chart1.xAxis.data);
        if (this.maximumAxisLabelWidth >= 24){
            this.maximumAxisLabelWidth += 22;
        } else {
            this.maximumAxisLabelWidth += 10;
        }

        this.charts.push(chart1);
        if (!$.isEmptyObject(chart2Data)){
            chart2 = this.setChart(chart2Data, chart2);
            this.charts.push(chart2);
        }
    },
    
     findLongestStringLength: function(stringsArray) {
        let maxLength = 0;
        stringsArray.forEach(str => {
            if (str.length > maxLength) {
                maxLength = str.length;
            }
        });
        return maxLength;
    },    
    padStringFront: function(inputString, numberOfSpaces) {
        let inputStringLength = inputString.length;
        let finalNumberOfSpaces = numberOfSpaces > inputStringLength ? numberOfSpaces - inputStringLength : numberOfSpaces;
        // Create a string of spaces with the specified length
        const padding = ' '.repeat(finalNumberOfSpaces);
        // Concatenate the padding to the front of the input string
        return padding + inputString;
    },    
    setChart: function(needsData, chart){
        chart = this.clearChart(chart);
        let xAxisData = [];
        let emotionalNeedsNameOrder = [];
        for (let needsIndex = 0; needsIndex < needsData.data.length; needsIndex++) {
            let companyProperties = needsData.data[needsIndex];
            if (companyProperties.length >= 3) {
                let seriesIndex = -1;
                for (let propertyIndex = 0; propertyIndex < companyProperties.length; propertyIndex++) {
                    let property = companyProperties[propertyIndex];
                    if (typeof property != 'undefined' && property != null ) {
                        if (propertyIndex == 0){
                            let name = property;
                            if (ProfileService.isEmotionalNeedName(property) ){
                                if (chart.containerId !==  "value_emotional_needs_chart" && chart.containerId !==  "profile_match_by_value_emotional_chart"){
                                    name = ProfileService.setDimensionNameForProfileChart(property);
                                } 
                                emotionalNeedsNameOrder.push(name);
                                name = this.padStringFront(name, this.maximumAxisLabelWidth);
                            }
                            xAxisData.push(name.trim());
                        } else if (propertyIndex == 1){
                            chart.series[seriesIndex].data.push(property);
                        } else if (propertyIndex == 2){
                            chart.series[seriesIndex].data.push(property);
                        } else if (this.displayPricingPower && propertyIndex == this.pricingPowerSeriesNumber){
                            let pricingPower = companyProperties[this.pricingPowerSeriesNumber];
                            if (typeof pricingPower != 'undefined' && pricingPower != null ) {
                                if (pricingPower < 0){
                                    pricingPower = 0;
                                }
                                if (parseFloat(pricingPower) > parseFloat(this.maxPricingPower)){
                                    this.maxPricingPower = pricingPower;
                                }   
                                chart.series[seriesIndex].data.push(pricingPower);
                            }                        
                        } else if (seriesIndex < chart.series.length){
                            chart.series[seriesIndex].data.push(property);
                        }
                        seriesIndex++;
                    }
                }
            }            
        }
        if ("moat_functional_needs_chart" == chart.containerId || "profile_match_by_moat_functional_chart" == chart.containerId){
            chart.xAxis.splitLine.lineStyle.width = 4;
        }
        if ("emotional_needs_chart" == chart.containerId || "profile_match_by_need_emotional_chart" == chart.containerId){
            this.emotionalNeedsNameOrder = ProfileService.setEmotionalNeedsHeaderOrder(chart.header.data, emotionalNeedsNameOrder);
        }
        if ("value_emotional_needs_chart" == chart.containerId || "profile_match_by_value_emotional_chart" == chart.containerId){
            for (let i = 0; i < chart.series.length; i++) {        
                chart.series[i].data.reverse();
            }    
        }
        chart.xAxis.data = JSON.parse(JSON.stringify(xAxisData));
        return chart;
    },
    getChartOptions: function(){
        let options = {};
        let showToolbox =  !(ReportService.getReportAutoChartGenerateStatus() == "generate");
        let colorPaletteId = this.eChartCriteria.colorPaletteId;
        let showLegend = this.eChartCriteria.showLegend;
        let showLabel = false;
        let angleText = true;
        let isMultipleWaves = true;
        let displayNumberOfRespondents = this.eChartCriteria.displayNumberOfResponses;
/* Start of function that should go into helper service */
        let xAxis = [];
        let seriesData = [];
        for (let i = 0; i < this.charts.length; i++) {
            let chart = this.charts[i];
            for (let i = 0; i < chart.series.length; i++) {
                let series = chart.series[i];
                seriesData.push(series);
            }
            $.extend(true, chart.xAxis.axisLabel, ProfileService.getDefaultAxisTextStyleForAnalyticChart());
            if (angleText == true){           
                $.extend(true, chart.xAxis.axisLabel, {rotate: 25,hideOverlap: true,interval: 0});
            } else {
                let defaultAxisLabel = {
                    overflow: "break",
                    interval: 0,
                    width: 40,
                    ellipsis: '...',        
                    rotate: 0,
                    hideOverlap: false,
                };
                $.extend(true, chart.xAxis.axisLabel, defaultAxisLabel);
            }
            xAxis.push(chart.xAxis);
            xAxis.push(chart.header);
        }
/* End of function that should go into helper service */
        ScatterService.setShowSnap(this.isSavedCard);
        ScatterService.setShowToolbox(showToolbox);
        ScatterService.setColorPaletteId(colorPaletteId);
        ScatterService.setShowLabel(showLabel);
        ScatterService.setShowLegend(showLegend);
        ScatterService.setIsMultiWave(isMultipleWaves);
        ScatterService.setChartType(this.eChartCriteria.eChartType);
        let legendData = ProfileService.buildLegendData(this.legend,displayNumberOfRespondents);
        ScatterService.setLegendData(legendData);
        ScatterService.setSeriesData(seriesData);
        if (this.eChartCriteria.eChartType == "stacked-bar-vertical"){
            let displayPricingPowerLabel = "value_functional_needs_chart" == this.charts[0].containerId || "profile_match_by_value_functional_chart" == this.charts[0].containerId;
            ScatterService.setYAxis(ProfileService.getYAxis(Math.ceil(this.maxPricingPower),this.charts.length,this.displayPricingPower,displayPricingPowerLabel));
            ScatterService.setXAxis(xAxis);
        } else if (this.eChartCriteria.eChartType == "bar-horizontal"){
            let left = [];
            let right = [];
            for (let i = 0; i < this.charts[0].xAxis.data.length; i++) {
                let emotionalName = this.charts[0].xAxis.data[i].split(","  );
                left.push(emotionalName[1]);
                right.push(emotionalName[0]);
            }
            left.reverse();
            right.reverse();
            let emotionalNeedNames = [];
            emotionalNeedNames.push(left);
            emotionalNeedNames.push(right);
            emotionalNeedNames.push(this.driverNames);
            ScatterService.setYAxis(ProfileService.getYAxisForValueDrivers(emotionalNeedNames));
            ScatterService.setXAxis(ProfileService.getXAxisForValueDrivers());
        }
        options = ScatterService.getOptions(this,this.modalId, this.inputReportTypeName);
        if (!$.isEmptyObject(options)) { 
            //override normal options
            options.tooltip.formatter = ProfileService.toolTipForProfileAreaChart;
            options.tooltip.backgroundColor = "#ffffff";
            options.color = this.colors;
            options.series[1].label.color = "#2F5EB3";            
            $.extend(true, options.toolbox, ProfileService.getToolboxForAnalyticsChart());
        }
        return options;           
    },
    getHeaderWidths: function(tickCoordinates){
        let headerWidths = [];
        for (let i = 0; i < tickCoordinates.length; i++) {
            let width = 0;
            let coord = tickCoordinates[i];
            if (i == 0){
                width = coord - 0;
            } else {
                width = coord - tickCoordinates[i-1];
            }
            headerWidths.push(width);
        }
        return headerWidths;
    },
    getStringWidth: function (text, font = '16px Arial') {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = font;
        return context.measureText(text).width;
    },
    updatedWinnerHeaderSeries: function(headerSeries,names,numberOfNeeds){
        let populatedNames = names.filter(name => name != ' ');
        if (populatedNames.length == 1 && headerSeries.markArea.data.length > 0 && headerSeries.markArea.data[0].length > 0){
            headerSeries.markArea.data[0][0].label.width = 600;
            headerSeries.markArea.data[0][0].label.name = populatedNames[0];        
            headerSeries.markArea.data[0][0].label.formatter =  "{a|"+populatedNames[0]+"}";
            headerSeries.markArea.data[0][0].xAxis = "1";
            numberOfNeeds = numberOfNeeds - 1;
            headerSeries.markArea.data[0][1].xAxis = numberOfNeeds;
        }
        headerSeries.markArea.data.pop();
        headerSeries.markArea.data.pop();

        return headerSeries;
    },
    updatedHeaderSeries: function(headerSeries,ticksIndex,displayType){
        let needTickCoordinates = this.chart._model._componentsMap.get('xAxis')[ticksIndex].axis.getTicksCoords();
        let tickCoordinates = needTickCoordinates.map(function (tick) {
            return tick.coord;
        });
        let headerWidths = this.getHeaderWidths(tickCoordinates);
        for (let i = 0; i < headerSeries.markArea.data.length; i++) {
            if (displayType == "winner" || displayType == "match-winner"){
                //if (headerWidths[i] > 0){
                    headerSeries.markArea.data[i][0].label.width = headerWidths[i]-1;
                //}
            } else if (displayType == "need-emotional"){                
                headerSeries.markArea.data[i][0].label.width = 200;
            } else {
                headerSeries.markArea.data[i][0].label.width = headerWidths[i]-1;
            }
            let valueWidth = this.getStringWidth(headerSeries.markArea.data[i][0].name);
            let labelWidth = headerSeries.markArea.data[i][0].label.width;
            let isValueGreaterLabel =  (valueWidth-50) > labelWidth;
            
            if (isValueGreaterLabel){
                let newName = headerSeries.markArea.data[i][0].name;
                let differencePercent = labelWidth / valueWidth;
                let substrValue = Math.ceil(newName.length * differencePercent);
                newName = newName.substr(0, substrValue-1) + "…";    
                headerSeries.markArea.data[i][0].name = newName;
                headerSeries.markArea.data[i][0].label.formatter = "{a|"+newName+"}";
            }
            if (i == 0){
                if (displayType == "value" || displayType == "match-value"){
                    headerSeries.markArea.data[i][0].label.offset = [12.25,0];
                } else if (displayType == "need-emotional"){
                    headerSeries.markArea.data[i][0].label.offset = [9.75,0];
                } else if (displayType == "winner" || displayType == "match-winner"){
                    headerSeries.markArea.data[i][0].label.offset = [9,0];
                } else if (displayType == "moat" || displayType == "match-moat"){
                    headerSeries.markArea.data[i][0].label.offset = [6,0];
                } else {
                    headerSeries.markArea.data[i][0].label.offset = [9,0];
                }
            } 
            if (displayType == "moat"){
                headerSeries.markArea.data[i][0].label.backgroundColor = "#4d8352";
            }            
        }
        return headerSeries;
    },
    processChart: async function(runChart = false,displayType,needsData,eChartCriteria,displayPricingPower = false,matchType = ""){
        if (runChart == true) {
            await this.getDefaultSymbol();
            if (matchType != ""){
                this.matchType = matchType;
            }
            let emotionalNeedsBreakPointList = [0,1,2,3];
            if (typeof eChartCriteria != "undefined") {
                this.eChartCriteria = JSON.parse(JSON.stringify(eChartCriteria));
            }
            this.displayPricingPower = displayPricingPower;
            this.maxPricingPower = 0;
            let driverList = [];
            if (typeof needsData != "undefined" && typeof needsData.driverList != "undefined") {
                driverList = JSON.parse(JSON.stringify(needsData.driverList));
            }

            // get the splits for charts
            let breakPointList = [];
            if (typeof needsData != "undefined" && typeof needsData.breakPointList != "undefined") {
                breakPointList = JSON.parse(JSON.stringify(needsData.breakPointList));
            }
            let needsList = [];
            if (typeof needsData != "undefined" && typeof needsData.needsList != "undefined") {
                needsList = JSON.parse(JSON.stringify(needsData.needsList));
                needsList = needsList.filter(function (need) {
                    return need.needName != "";
                });
            }
            let numberOfNeeds = needsList.length;
            if (displayType == "moat" || displayType == "match-moat"){
                let names = ['Value','Design','Lock-Out','Innovation','Routine','Scale','Lock-In','Network','Time','Skill','Brand'];  
                let headerGroups = ['Core Moats', 'Early-Mover Moats', 'Promise Moats'];
                numberOfNeeds = 12;
                breakPointList = [2,8];
                let chart1 = {
                        containerId: "",
                        header: ProfileService.getBaseNeedsHeader(breakPointList,numberOfNeeds,displayType),
                        series: [],
                        xAxis: ProfileService.getXAxisForNeeds(displayType,breakPointList,true),                
                };
                let chart2 = {
                    containerId: "",
                    header: ProfileService.getEmotionalNeedsHeader(emotionalNeedsBreakPointList,5),
                    series: [],
                    xAxis:  ProfileService.getXAxisForEmotionalNeeds(),       
                };
                ScatterService.setGrid(ProfileService.getGridForProfileByNeedChart());
                this.setDataForNeed(needsData,chart1,chart2,displayType);
                chart1.series.push(ProfileService.getMoatHeaderSeries(displayType,names,numberOfNeeds,0,1,0,this.displayPricingPower,headerGroups,ScatterService.getGrid()));
                numberOfNeeds = 5;
                names = ['Assurance','Curiosity','Strictness','Trust','Activity'];  
                headerGroups = ['Emotional Levels'];
                let series = ProfileService.getMoatHeaderSeries(displayType,names,numberOfNeeds,1,3,this.yAxisForChart2,this.displayPricingPower,headerGroups,ScatterService.getGrid());
                series.markLine = ProfileService.getDefaultMarkLineForEmotionalNeeds();
                chart2.series.push(series);
                this.initChart();
            } else if (displayType == "need" || displayType == "match-need"){
                let chart1 = {
                    containerId: "",
                    header: ProfileService.getFunctionalNeedsHeader(breakPointList,numberOfNeeds,displayType),
                    series: [],
                    xAxis: ProfileService.getXAxisForNeeds(displayType,breakPointList),                
                };
                let chart2 = {
                    containerId: "",
                    header: ProfileService.getEmotionalNeedsHeader(emotionalNeedsBreakPointList,5),
                    series: [],
                    xAxis:  ProfileService.getXAxisForEmotionalNeeds(),       
                };
                ScatterService.setGrid(ProfileService.getGridForProfileByNeedChart());

                this.setDataForNeed(needsData,chart1,chart2,displayType);
                let names = ["Top Quartile","Second Quartile","Third Quartile","Fourth Quartile"];
                chart1.series.push(ProfileService.getBaseNeedsHeaderSeries(names,breakPointList,numberOfNeeds,0,1,0));
                numberOfNeeds = 1;
                emotionalNeedsBreakPointList = [0];
                this.emotionalNeedsNameOrder = ['Emotional Levels'];
                let emotionalNeedsHeaderSeries = ProfileService.getBaseNeedsHeaderSeries(this.emotionalNeedsNameOrder,emotionalNeedsBreakPointList,numberOfNeeds,1,3,this.yAxisForChart2,0,true);
                emotionalNeedsHeaderSeries.markArea.data[0][0].label.width = 200;                
                emotionalNeedsHeaderSeries.markArea.data[0][1].xAxis = 5;
                chart2.series.push(emotionalNeedsHeaderSeries);
                this.initChart();
                let headerSeries = this.updatedHeaderSeries(chart1.series[chart1.series.length-1],1,displayType);
                chart1.series.pop();
                chart1.series.push(headerSeries);
                let emotionalHeaderSeries = this.updatedHeaderSeries(chart2.series[chart2.series.length-1],3,"need-emotional");
                chart2.series.pop();
                chart2.series.push(emotionalHeaderSeries);
                this.initChart();
            } else if (displayType == "winner" || displayType == "match-winner"){
                let chart1 = {
                    containerId: "",
                    header: ProfileService.getWinnersHeader(breakPointList,numberOfNeeds,displayType),
                    series: [],
                    xAxis: ProfileService.getXAxisForNeeds(displayType,breakPointList),                
                };
                ScatterService.setGrid(ProfileService.getGridForSingleChart());
                this.setDataForNeed(needsData,chart1,{},displayType);
                let names = [];
                let onlyDisplayOneWinnerName = false;
                let defaultWidth = 0;
                if (typeof needsData != "undefined" && typeof needsData.winnerMapNames != "undefined") {
                   names = JSON.parse(JSON.stringify(needsData.winnerMapNames));
                   defaultWidth = needsData.winnerMapNames.filter(name => name != ' ').length > 1 ? 0 : 450;
                   onlyDisplayOneWinnerName = needsData.winnerMapNames.filter(name => name != ' ').length == 1;
                }                
                
                chart1.series.push(ProfileService.getBaseNeedsHeaderSeries(names,breakPointList,numberOfNeeds,0,1,0,defaultWidth));
                this.initChart();
                if (names.length > 1){
                    let headerSeries = onlyDisplayOneWinnerName ? this.updatedWinnerHeaderSeries(chart1.series[chart1.series.length-1], names,numberOfNeeds) : 
                                this.updatedHeaderSeries(chart1.series[chart1.series.length-1],1,displayType,onlyDisplayOneWinnerName);
                    chart1.series.pop();
                    chart1.series.push(headerSeries);
                    this.initChart();
                }
            } else if (displayType == "value" || displayType == "match-value"){
                let chart1 = {
                    containerId: "",
                    header: ProfileService.getWinnersHeader(breakPointList,numberOfNeeds,displayType),
                    series: [],
                    xAxis: ProfileService.getXAxisForNeeds(displayType,breakPointList),                
                };
                let grid = ProfileService.getGridForSingleChart();
                ScatterService.setGrid(grid);
                this.setDataForNeed(needsData,chart1,{},displayType);
                let names = driverList.map(driver => driver.name);
                chart1.series.push(ProfileService.getBaseNeedsHeaderSeries(names,breakPointList,numberOfNeeds,0,1,0));
                this.initChart();
                let headerSeries = this.updatedHeaderSeries(chart1.series[chart1.series.length-1],1,displayType);
                chart1.series.pop();
                chart1.series.push(headerSeries);
                this.initChart();
            } else if (displayType == "value-driver" || displayType == "match-value-driver"){
                this.driverNames = driverList.map(driver => driver.name).reverse();
                let chart1 = {
                    containerId: "",
                    header: ProfileService.getWinnersHeader(breakPointList,numberOfNeeds,displayType),
                    series: [],
                    xAxis: ProfileService.getXAxisForNeeds(displayType,breakPointList),                
                };
                ScatterService.setGrid(ProfileService.getGridForValueDriverChart());
                this.setDataForNeed(needsData,chart1,{},displayType);
                chart1.series.push(...ProfileService.getValueDriverHeaderSeries(0,0,0));
                this.initChart();
            }
            if (typeof needsData != "undefined" && typeof eChartCriteria != "undefined" && this.charts.length > 0) {
                this.$emit('save-criteria',true);
                this.$emit('save-table');

            }
        }
    },
  },
  watch: {},
};
</script>
